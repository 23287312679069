import Input from 'components/formitems/input';
import Products from 'components/pageitems/products';
import { ContextProps, GlobalContext } from 'context/global-context';
import { getSearchData } from 'data/product';
import { ListProduct } from 'data/types';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';
import { useDebounce } from 'react-use';
import Loader from './load-spinner';

export type SearchResultProps = {
  query?: string;
  simple?: boolean;
};

export const SearchResult: FC<SearchResultProps> = ({
  query = '',
  simple = false,
}) => {
  const router = useRouter();
  const { dictionary } = useContext<ContextProps>(GlobalContext);
  const [searchQuery, setSearchQuery] = useState(query || '');
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<ListProduct[]>([]);

  const fetchResult = async (value: string): Promise<void> => {
    setLoading(true);
    const result = await getSearchData(value, router.locale);
    setSuggestions(result || []);
    setLoading(false);
  };

  useDebounce(
    () => {
      fetchResult(searchQuery);
    },
    700,
    [searchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      fetchResult(searchQuery);
    }
  }, []); // eslint-disable-line

  return (
    <StyledSearchResult>
      {!simple && (
        <>
          <div className="searchbar">
            <Input
              placeholder={dictionary.searchProducts}
              onChange={(e) => {
                setLoading(true);
                setSearchQuery(e.target.value);
              }}
              value={searchQuery}
            />
            <div className="icon">
              {loading ? <Loader /> : <FiSearch size={24} />}
            </div>
          </div>
          {!loading && (
            <div className="summery">
              {suggestions.length > 0 ? (
                <h4>
                  {dictionary.searchFound} {suggestions.length}{' '}
                  {dictionary.searchFoundProducts}
                </h4>
              ) : (
                <h4 className="no-results">
                  {dictionary.queryDidntMatch} {searchQuery}
                </h4>
              )}
            </div>
          )}
        </>
      )}
      {simple && suggestions.length > 0 && (
        <div className="simple-summery">{dictionary.pageNotSearchResults}</div>
      )}
      <Products products={suggestions} small rootLink />
    </StyledSearchResult>
  );
};

export const StyledSearchResult = styled.div`
  .summery {
    padding: 1rem var(--side-padding);
  }

  .simple-summery {
    padding: 1rem var(--side-padding);
  }

  .searchbar {
    padding: 1rem var(--side-padding);
    max-width: 600px;
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      right: var(--side-padding);
      margin-right: 5px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
      color: var(--input-foreground);
      align-self: flex-start;
    }
  }
`;

export default SearchResult;
